<template>
  <v-container class="form__container pt-6" fluid>
    <v-row>
      <v-col md="4">
        <span class="text-h6">{{ $trans("invoice_data") }}</span>
      </v-col>
    </v-row>
    <v-row v-if="getIsReverseChargeApplied">
      <v-col>
        <calendesk-information-message>
          {{ $trans("reverse_charge_info") }}
        </calendesk-information-message>
      </v-col>
    </v-row>
    <v-form ref="InvoiceDataForm" @submit.prevent="handleSaveCompanyData">
      <c-company-data
        ref="company_data"
        :loading="updatingInvoiceData"
        :my-form="form.default_address"
        required
        @defaultAddress="getDefaultAddress"
      />
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import invoiceActions from "@/calendesk/mixins/invoiceActions";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  name: "InvoiceDataForm",
  components: { CalendeskInformationMessage },
  mixins: [invoiceActions],
  data() {
    return {
      updatingInvoiceData: false,
    };
  },
  computed: {
    ...mapGetters({
      getIsReverseChargeApplied: "payments/getIsReverseChargeApplied",
    }),
  },
  methods: {
    handleSaveCompanyData() {
      if (this.$refs.InvoiceDataForm.validate()) {
        this.updatingInvoiceData = true;
        this.saveInvoiceData()
          .then(() => {
            successNotification("invoice_data_updated_successfully");
            this.$emit("saved");
          })
          .catch((error) => {
            errorNotification("invoice_data_update_failed", error);
          })
          .finally(() => {
            this.updatingInvoiceData = false;
          });
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
  },
};
</script>
