<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <h6 class="text-h6" v-text="$trans('payment_method')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <payments-method />
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
    <invoice-data-form />
  </div>
</template>

<script>
import InvoiceDataForm from "@/views/dashboard/pages/Billing/components/InvoiceDataForm.vue";
import PaymentsMethod from "@/views/dashboard/pages/Billing/components/PaymentMethod.vue";

export default {
  name: "PaymentsSettings",
  components: { PaymentsMethod, InvoiceDataForm },
};
</script>
